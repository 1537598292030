// SECTION General
export const USD_CURRENCY_ID = 1;
export const CAD_CURRENCY_ID = 2;
export const MXN_CURRENCY_ID = 3;

export const ExtendedReferenceTypeEnum = {
  BILL_OF_LADING: 8,
};

// SECTION Carriers
export const PRODUCTION_USAGE_INDICATOR_ID = 1;
export const RAIL_CARRIER_TYPE_ID = 1;
export const NONE_SHIPMENT_CANCELLATION_TYPE_ID = 1;
export const RAIL_BOL_TRANSACTION_SET_ID = 404;

// SECTION Clients
export const BOL_TYPE_DISABLED_ID = 1;
export const BOL_TYPE_CLIENT_ID = 2;
export const BOL_TYPE_PATTERN_ID = 3;
export const RATE_NO_ACCRUAL_ID = 1;
export const RATE_FREIGHT_ACCRUAL_ID = 2;
export const RATE_NONE_EXPORT_ID = 1;
export const RATE_EPICOR_EXPORT_ID = 2;
export const RATE_CLIENT_EXPORT_ID = 3;

export const ClientHazardousContactTypeEnum = {
  MANUAL: 1,
  GLOBAL: 2,
  USER: 3,
};

// SECTION Form Input Types
export const FORM_INPUT_TYPE_DATE_ID = 2;
export const FORM_INPUT_TYPE_DATETIME_ID = 3;
export const FORM_INPUT_TYPE_TEXT_ID = 4;
export const FORM_INPUT_TYPE_DROPDOWN_ID = 5;

// SECTION Patterns
export const RULE_11_PAYMENT_METHOD_ID = 1;
export const RULE_11_PARTY_TYPE_ID = 6;
export const PAYER_FREIGHT_PARTY_TYPE_ID = 4;
export const IN_CARE_OF_PARTY_TYPE_ID = 15;
export const PICKUP_PARTY_TYPE_ID = 31;
export const CONSIGNEE_PARTY_TYPE_ID = 1;
export const SHIPPER_PARTY_TYPE_ID = 36;
export const CAR_LOAD_PACKING_FORM_ID = 1;
export const SPECIAL_PROGRAM_CONTACT_TYPE_ID = 14;

// SECTION Rate Requests
export const REQUEST_STATUS_OPEN_ID = 1;
export const REQUEST_STATUS_IN_PROGRESS_ID = 2;
export const REQUEST_STATUS_NEEDS_MORE_INFO_ID = 3;
export const REQUEST_STATUS_PENDING_APPROVAL_ID = 4;
export const REQUEST_STATUS_ACCEPTED_ID = 5;
export const REQUEST_STATUS_DECLINED_ID = 6;

// SECTION Shipments
export const CREATED_EVENT_ID = 1;
export const UPDATED_EVENT_ID = 2;

export const ShipmentStatusTypeEnum = {
  PENDING: 1,
  SHIPPED: 2,
  CANCELLED: 3,
  COMPLETED: 4,
  SPLITTING: 5,
  REVIEW: 6,
} as const;

// SECTION Parties
export const JOINT_RAILROAD_STATUS_ID = 3;
export const OPEN_RAILROAD_STATUS_ID = 1;
export const RESTRICTED_RAILROAD_STATUS_ID = 5;
export const IMPORTER_PARTY_ID = 23;
export const SHOP_PARTY_ID = 44;

// SECTION Party Billing Segments
export const R11_PREPAID_BEYOND = 1;
export const R11_COLLECT_BEYOND = 2;
export const STRAIGHT_REBILL = 3;

// SECTION Notifications Types
export const NOTIFICATION_TYPE_EMAIL = 1;
export const NOTIFICATION_TYPE_EMAIL_ATTACHMENT = 2;
export const NOTIFICATION_TYPE_FTP = 4;

//  SECTION Equipments
export const IDLER_CAR_ID = 10;
export const RAIL_CAR_ID = 11;
export const CAR_LOAD_ID = 1;
export const TANK_CAR_ID = 6;
export const NET_ID = 3;
export const SHIPPER_SCALE_WEIGHT_TYPE_ID = 12;
export const IMPOUNDED_EQUIPMENT_STATUS_ID = 8;

// SECTION Order Status Types
export const ORDER_PENDING = 1;
export const ORDER_PARTIALLY_SHIPPED = 2;
export const ORDER_SHIPPED = 3;
export const ORDER_CANCELED = 4;
export const ORDER_SALES_ORDER = 1;

// SECTION Rate Units
export const RATE_UNIT_PER_CAR_ID = 2;
export const RATE_UNIT_PERCENTAGE = 8;

// SECTION Commodity
export const COMMODITY_MEXICO_MATERIAL_TYPE_OTHER_ID = 5;

// SECTION Invoice
export const INVOICE_APPROVED_STATUS_ID = 2;
export const INVOICE_EXPORTED_STATUS_ID = 14;
export const INVOICE_FREIGHT_CHARGE_TYPE = 1;
export const INVOICE_FUEL_CHARGE_TYPE = 2;
export const InvoiceTypes = {
  FREIGHT: 1,
  MAINTENANCE: 2,
  LEASE: 3,
  ASSESSORIAL: 4,
} as const;

// SECTION Maintenance Events
export const IN_ROUTE_EVENT_STATUS_ID = 9;
export const AT_SHOP_EVENT_STATUS_ID = 10;
export const PENDING_PAPERWORK_EVENT_STATUS_ID = 12;
export const APPROVED_ESTIMATE_COST_HISTORY_ID = 1;

// SECTION Role Names
export const EDF_MAN_CUSTOMER_ROLE_NAME = 'ED&F Man Customer';
